import { useState, useEffect } from 'react';

import { formLabel, inputField } from './utilities';

function Content() {
  const [formData, setFormData] = useState({
    home: '',
    menu: [],
    about: [],
    join: {},
    errors: {},
    loading: false
  });

  useEffect(() => {
    fetch('/.netlify/functions/api?table=home').then(response => {
      response.json().then(json => {
        setFormData((prevState) => ({ ...prevState, home: json[0].content }));
      });
    });
    fetch('/.netlify/functions/api?table=menu').then(response => {
      response.json().then(json => {
        const items = {};
        json.forEach(({ course, ...rest }) => {
          if (!items[course]) {
            items[course] = [];
          }
          items[course].push(rest);
        });
        setFormData((prevState) => ({ ...prevState, menu: items }));
      });
    });
    fetch('/.netlify/functions/api?table=about').then(response => {
      response.json().then(json => {
        setFormData((prevState) => ({ ...prevState, about: json }));
      });
    });
    fetch('/.netlify/functions/api?table=join').then(response => {
      response.json().then(json => {
        setFormData((prevState) => ({ ...prevState, join: { message: json[0].message, fineprint: json[0].fineprint } }));
      });
    });
  }, []);

  const handleHomeChange = event => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAboutChange = event => {
    const { name, value } = event.target;
    const index = name.match(/\d+/)[0];
    let { about } = formData;
    about[index].content = value;
    setFormData((prevState) => ({ ...prevState, about }));
  };

  const handleJoinChange = event => {
    // const { name, value } = event.target;
    // const index = name.match(/\d+/)[0];
    // let { about } = formData;
    // about[index].content = value;
    // setFormData((prevState) => ({ ...prevState, join }));
  };

  const renderCourses = () => {
    return Object.keys(formData.menu).map(key => {
      if (formData.menu[key]) {
        return renderCourseLabel(key) && renderCourse(key);
      }
      return null;
    })
  }

  const renderCourseLabel = course => <label className={formLabel}>{course}</label>;

  const handleCourseChange = event => {
    const { name, value } = event.target;
    console.log('name', name);
    console.log('value', value);
  };

  const renderCourse = course => {
    return formData.menu[course].map((item, index) => (
      <div className="border-b-2 border-black mb-5" key={`key-${index}`}>
        <input className={inputField} type="text" name={`product-${item.id}`} value={item.product} onChange={handleCourseChange} />
        <textarea className={`${inputField} no-resize h-24 resize-none`} name={`description-${item.id}`} value={item.description} onChange={handleCourseChange}></textarea>
        <input className={inputField} type="text" name={`price-${item.id}`} value={item.price} onChange={handleCourseChange} />
        <div className="flex">
          <input disabled className={`w-48 ${inputField}`} type="text" id="label" value="Is Vegetarian" />
          <div className="w-48">
            <input className="toggle relative top-3 left-5" type="checkbox" name={`isvegetarian-${item.id}`} checked={item.isvegetarian} onChange={handleCourseChange} />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="main-container container mx-auto mt-8 p-1 sm:p-2">
        <form className="w-full mx-auto">
          <div className="join join-vertical w-full">
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-xl font-medium">
                Home
              </div>
              <div className="collapse-content">
                <div className="w-full">
                  <label className={formLabel} htmlFor="home">Content</label>
                  <textarea className={`${inputField} no-resize h-48 resize-none`} id="home" value={formData.home} name="home" onChange={handleHomeChange}></textarea>
                </div>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                About
              </div>
              <div className="collapse-content">
                <div className="w-full">
                  <label className={formLabel}>Content</label>
                  {formData.about.map((paragraph, index) => <textarea className={`${inputField} no-resize h-48 resize-none`} value={paragraph.content} key={`about[${index}]`} name={`about[${index}]`} onChange={handleAboutChange}></textarea>)}
                </div>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                Join
              </div>
              <div className="collapse-content">
                <div className="w-full">
                  <label className={formLabel} htmlFor="join-message">Message</label>
                  <textarea className={`${inputField} no-resize h-48 resize-none`} id="join-message" value={formData.join.message} name="join-message" onChange={handleJoinChange}></textarea>
                </div>
                <div className="w-full">
                  <label className={formLabel} htmlFor="join-fineprint">Fineprint</label>
                  <textarea className={`${inputField} no-resize h-48 resize-none`} id="join-fineprint" value={formData.join.fineprint} name="join-fineprint" onChange={handleJoinChange}></textarea>
                </div>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                Menu
              </div>
              <div className="collapse-content">
                <div className="w-full">
                  {renderCourses()}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Content;
