import { useState } from 'react';

import { formLabel, inputField } from './utilities';

export default function Contact() {
  const [formData, setFormData] = useState({
    topic: '',
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    errors: {},
    loading: false,
    responseMessage: ''
  });
  const [fadeIn, setFadeIn] = useState('');

  const isValidEmail = emailAddress => String(emailAddress).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const validateForm = () => {
    const errors = {};
    if (!formData.topic.length) {
      errors.topic = 'Required';
    }
    if (!formData.firstName.length) {
      errors.firstName = 'Required';
    }
    if (!formData.lastName) {
      errors.lastName = 'Required';
    }
    if (!formData.email) {
      errors.email = 'Required';
    } else {
      if (!isValidEmail(formData.email)) {
        errors.email = 'Invalid';
      }
    }
    if (!formData.message) {
      errors.message = 'Required';
    }
    return errors;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const errors = validateForm();
    setFormData((prevState) => ({ ...prevState, [name]: value, errors }));
  };

  const validate = () => {
    const errors = validateForm();
    setFormData((prevState) => ({ ...prevState, errors }));
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (validate()) {
      setFormData({
        ...formData,
        loading: true
      });

      const { topic, email, firstName, lastName, message } = formData;
      fetch(`/.netlify/functions/email`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ from: email, subject: topic, text: `${message}\n-${firstName} ${lastName}` })
      }).then(emailResponse => {
        setFormData((prevState) => ({ ...prevState, loading: false, responseMessage: emailResponse.status === 200 ? 'Message Sent!' : 'Oops! Something went wrong with the ones and zeros!' }));
      }).catch(error => {
        console.error('There was a critical error sending the email.');
        console.error(error);
      });
    }
  };

  const withError = key => formData.errors[key] ? 'border border-error' : '';

  const selectOptions = [
    { value: 'General Inquiry', label: 'General Inquiry' },
    { value: 'Catering', label: 'Catering' },
    { value: 'Suggestion', label: 'Suggestion' },
    { value: 'Issue', label: 'Issue' }
  ];

  setTimeout(() => {
    setFadeIn('is-visible');
  }, 350);

  return (
    <div className={`main-container container mx-auto mt-8 p-1 sm:p-2 fade-in ${fadeIn}`}>
      <form className="w-full max-w-xl mx-auto">
        <div className="w-full">
          <label className={formLabel} htmlFor="topic">Topic</label>
          <select className={`${inputField} ${withError('topic')} select`} value={formData.topic} name="topic" id="topic" onChange={handleChange}>
            <option disabled value={''}></option>
            {selectOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
          </select>
        </div>
        <div className="w-full">
          <label className={formLabel} htmlFor="first-name">First Name</label>
          <input autoComplete="on" className={`${inputField} ${withError('firstName')}`} id="first-name" type="text" placeholder="Jane" name="firstName" value={formData.firstName} onChange={handleChange} />
        </div>
        <div className="w-full">
          <label className={formLabel} htmlFor="last-name">Last Name</label>
          <input autoComplete="on" className={`${inputField} ${withError('lastName')}`} id="last-name" type="text" placeholder="Doe" name="lastName" value={formData.lastName} onChange={handleChange} />
        </div>
        <div className="w-full">
          <label className={formLabel} htmlFor="email">E-mail</label>
          <input className={`${inputField} ${withError('email')}`} id="email" type="email" placeholder="jane.doe@email.com" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div className="w-full">
          <label className={formLabel} htmlFor="message">Message</label>
          <textarea className={`${inputField} ${withError('message')} no-resize h-48 resize-none`} id="message" placeholder="Message..." name="message" value={formData.message} onChange={handleChange}></textarea>
        </div>
        <div className="w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <button className="shadow bg-black font-bold py-2 px-4 rounded" type="button" disabled={formData.loading} onClick={handleFormSubmit}>Send</button>
            <div className="flex justify-center items-center my-2 text-error">
              <span className="text-error">{Object.keys(formData.errors).length !== 0 ? 'All fields required.' : ''}</span>
              <span className="text-success">{formData.responseMessage}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
