const serializeResponse = (_key, value) => typeof value === 'bigint' ? value.toString() : value;

const currentPage = () => window.location.pathname.slice(1);

const isAdminPage = () => window.location.pathname.indexOf('admin') > -1;

const firstLetter = 'text-6xl sm:text-8xl md:text-9xl lg:text-10xl';

const letters = 'text-5xl sm:text-7xl md:text-8xl lg:text-9xl';

const blackBar = 'bg-black w-full h-2 md:h-4 mb-4';

const formLabel = 'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2';

const inputField = 'appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white';

const stringToObject = value => {
  const params = {};
  const pairs = value.split('&');
  let i = 0;
  const length = pairs.length;
  for (i; i < length; i++) {
    const pair = pairs[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] || '');
    if (key.length) {
      params[key] = value;
    }
  }
  return params;
};

const formatNumber = phoneNumber => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, '');
  if (number.length < 4) {
    return number;
  }
  if (number.length < 7) {
    return number.replace(/(\d{3})(\d{1})/, '$1-$2');
  }
  return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
};

const patronExistsMessage = 'Congratulations!\n You\'re already a Lulu Patron!';

const loadingMessage = '';

export {
  currentPage,
  isAdminPage,
  serializeResponse,
  firstLetter,
  letters,
  blackBar,
  formLabel,
  inputField,
  stringToObject,
  formatNumber,
  patronExistsMessage,
  loadingMessage
};
